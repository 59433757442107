.customer {
  &.attention {
    .title {
      background-color: #c7c763;
    }
    .controls button {
      background-color: #c7c763;
      border-color: #c7c763;
    }
  }
}
