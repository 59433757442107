$colorPrimary: #1886cf;
$colorPrimaryBackground: rgb(247, 247, 247);
$colorSecondary: rgb(113, 185, 233);
$colorPrimarySuccess: #18cf74;
$colorSecondarySuccess: rgb(113, 233, 177);
$colorPrimaryError: #cf2418;
$colorSecondaryError: rgb(233, 113, 113);

a {
  color: black;
}
*:focus {
  outline: none;
}

.Toastify__toast-body {
  color: white;
}
.Toastify__close-button svg path {
  fill: white;
}

.sidebar {
  display: flex;
  flex-flow: column;
  transform: translateX(-220px);
  min-width: 220px;
  max-width: 220px;
  min-height: 100vh;
  position: fixed;
  padding: 0 0 15px 0;
  border-right: 1px solid rgb(184, 184, 184);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.5);
  animation: fly-in 300ms ease;
  animation-fill-mode: forwards;
  @keyframes fly-in {
    to {
      transform: translateX(0px);
    }
  }
  a {
    padding: 10px;
    text-decoration: none;
    box-shadow: 0 10px 5px -10px rgb(219, 219, 219);
    border-bottom: 1px solid rgb(219, 219, 219);
    &.active {
      font-weight: 600;
    }
    &:hover {
      background: $colorPrimary;
      color: white;
      svg,
      svg * {
        fill: white;
      }
    }
  }
}

.bar-warning {
  display: block;
  width: calc(100% - 20px);
  color: white;
  padding: 10px;
  text-align: center;
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0% {
    background: red
  }
  100% {
    background: #c70d0d
  }
}

.main {
  width: calc(100% - 60px);
  padding: 30px;
  &.logged-in {
    margin-left: 220px;
    width: calc(100% - 280px);
  }
  &.error-boundary-fallback {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
}
button,
select {
  cursor: pointer;
}

input,
select,
button {
  border: 1px solid gray;
  padding: 5px;
  background: $colorPrimaryBackground;
  margin: 5px 0 10px;
  font-size: 16px;
  border-radius: 10px;
  &:focus,
  &:hover {
    border-color: $colorPrimary;
  }
  &:focus {
    //background: initial; // TODO TEST
    // border-radius: 0;
  }
  &.btn-main {
    cursor: pointer;
    &:hover {
      background: $colorSecondary;
      color: white;
    }
  }
}

.hiddenInput {
  display: inline-block;
  &:hover {
    color: $colorPrimary;
  }
}

.canSort {
  cursor: pointer;
  &:hover {
    background: $colorSecondary !important;
  }
}

.navigation {
  margin: 0 -3px;
  div {
    display: inline-table;
    margin: 0 3px;
    &:hover {
      color: $colorPrimary;
      cursor: pointer;
    }
  }
}

.top-nav {
  margin-left: 30px;
  display: flex;
  position: absolute;
  z-index: 1;
  div {
    cursor: pointer;
    padding: 5px 10px 10px;
    border-radius: 10px;
    transition: margin 300ms;
    margin-top: -3px;
    &.active {
      margin-top: -10px;
      background: rgb(242, 242, 242);
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5), 0 10px 12px rgba(0, 0, 0, 0.15),
        0 10px 45px rgba(0, 0, 0, 0), inset 0 1px 0 white, inset 0 -2px 0 rgba(0, 0, 0, 0.1);
    }
  }
}

.login {
  .expired {
    text-align: center;
    background: white;
    padding: 10px 0px;
    margin: -40px -10px 20px;
    box-shadow: inset 0 -3px 5px -3px black;
    &.extend {
      background: red;
      margin: -20px -10px 20px;
    }
  }

  > div {
    max-width: 300px;
    background: rgb(242, 242, 242);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5), 0 10px 12px rgba(0, 0, 0, 0.15),
      0 10px 45px rgba(0, 0, 0, 0), inset 0 1px 0 white, inset 0 -2px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px 10px;
    margin: 20px auto;
    display: flex;
    flex-flow: column;
    overflow: hidden;
    position: relative;
    .title {
      font-size: 22px;
      font-weight: 800;
      background: $colorPrimary;
      color: white;
      padding: 20px 10px;
      margin: -20px -10px 10px -10px;
    }
    .input-text {
      display: flex;
      flex-flow: column;
      label {
        font-size: 16px;
      }
    }
  }
}

.feed {
  display: flex;
  flex-flow: column;
  .feed-box {
    max-width: 300px;
    background: rgb(242, 242, 242);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5), 0 10px 12px rgba(0, 0, 0, 0.15),
      0 10px 45px rgba(0, 0, 0, 0), inset 0 1px 0 white, inset 0 -2px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    border-right: 5px solid white;
    cursor: default;
    transition: all 200ms;
    position: relative;
    overflow: hidden;
    .background {
      content: "";
      background: rgba(255, 255, 255, 0.8);
      width: 30px;
      height: 150%;
      position: absolute;
      top: -25%;
      left: -25%;
      transform: rotate(25deg);
      transition: left 300ms cubic-bezier(0.25, 0.1, 0, 1), width 300ms ease;
      z-index: 1;
    }
    &:hover {
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5), 0 12px 15px rgba(0, 0, 0, 0.2),
        0 15px 45px rgba(0, 0, 0, 0.05), inset 0 1px 0 white, inset 0 -2px 0 rgba(0, 0, 0, 0.1);
      .background {
        left: 15%;
        width: 80px;
      }
    }
    *:not(.background) {
      z-index: 2;
    }
    border-color: rgba(21, 228, 124, 0.75) !important;
    &.reset {
      border-color: rgba(23, 189, 255, 0.75) !important;
    }
    &.fraud {
      border-color: rgba(255, 50, 23, 0.75) !important;
    }
    .feed-box-id {
      display: flex;
      align-items: center;
      position: relative;
      &:hover {
        color: rgb(122, 122, 122);
        cursor: pointer;
        .feed-box-logo {
          &:after {
            opacity: 1;
          }
        }
      }
    }
    .feed-box-flex {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      .feed-box-left {
        .feed-box-description {
          font-size: 18px;
          display: inline-block;
          margin: 5px 0px;
        }
        .feed-box-date {
          font-size: 13px;
          font-weight: 300;
        }
      }
      .feed-box-right {
        justify-content: space-around;
        display: flex;
        flex-flow: column;
        .feed-box-amount {
          font-size: 26px;
          font-weight: 200;
          display: inline-block;
          text-align: right;
          margin-right: 5px;
          div {
            font-size: 13px;
            font-weight: 300;
            white-space: nowrap;
          }
        }
      }
    }
    .feed-box-personal {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      font-size: 14px;
      position: relative;
      div,
      a {
        text-decoration: none;
        background: rgba(0, 0, 0, 0.08);
        padding: 5px;
        border-radius: 3px;
        margin-top: 5px;
        margin-right: 5px;
        &.customer {
          &:hover {
            background: rgba(0, 0, 0, 0.15);
            cursor: pointer;
          }
        }
        &.failed {
          background: rgba(255, 0, 0, 0.25);
          &:hover {
            background: rgba(255, 0, 0, 0.35);
          }
        }
        &.refunded {
          background: rgba(0, 100, 255, 0.25);
          &:hover {
            background: rgba(0, 100, 255, 0.35);
          }
        }
        &.disputed {
          background: rgba(255, 255, 0, 0.25);
          &:hover {
            background: rgba(255, 255, 0, 0.35);
          }
        }
      }
    }
  }
}

.main > .customers,
.main .feed,
.discounts {
  z-index: 3;
  background: rgb(242, 242, 242);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5), 0 10px 12px rgba(0, 0, 0, 0.15),
    0 10px 45px rgba(0, 0, 0, 0), inset 0 1px 0 white, inset 0 -2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px 30px 30px;
  margin: 20px auto;
  display: flex;
  flex-flow: column;
  // overflow: hidden;
  position: relative;
  font-size: 15px;
  table {
    border-spacing: 0;
    td {
      padding: 5px;
    }
    thead td {
      background: $colorPrimary;
      color: white;
      font-weight: bold;
    }
    tbody {
      tr:nth-child(even) td {
        background: rgba(128, 128, 128, 0.12);
      }
      tr:hover td {
        background: rgba(128, 128, 128, 0.22);
      }
    }
  }
  .new {
    &:not(.check) > div {
      width: calc(33% - 20px);
    }
    display: flex;
    justify-content: space-between;
    & > input,
    & > select {
      width: calc(33% - 20px);
    }
    &.check {
      display: block;
    }
  }
}

.main > .customer,
.fina,
.main > .discounts {
  .title {
    font-size: 22px;
    font-weight: 800;
    background: $colorPrimary;
    color: white;
    padding: 20px 30px;
    margin: -20px -30px 15px;
    overflow: hidden;
    position: relative;
    div {
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
      //margin-top: 4px;
      &:after {
        content: "#";
        position: absolute;
        font-size: 166px;
        font-weight: 900;
        opacity: 0.1;
        top: -50px;
        right: -20px;
      }
    }
  }
  &.warning {
    .title {
      background: $colorPrimaryError;
    }
    .controls button {
      background: $colorPrimaryError;
      border-color: $colorPrimaryError;
      &:hover {
        border-color: $colorPrimaryError;
        background: $colorSecondaryError;
      }
    }
  }
}

.main > .customer,
.fina {
  background: rgb(242, 242, 242);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5), 0 10px 12px rgba(0, 0, 0, 0.15),
    0 10px 45px rgba(0, 0, 0, 0), inset 0 1px 0 white, inset 0 -2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px 30px 30px;
  margin: 20px auto;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  position: relative;
  font-size: 15px;
  input:not(.MuiInputBase-input) {
    margin: 0;
    display: inline-table;
    width: 200px;
    border: none;
    border-bottom: 0 solid black;
    border-radius: 0;
    padding: 0 5px;
    font-size: 15px;
  }
  table {
    th {
      text-align: left;
      padding: 0 7px;
    }
    tr td {
      padding: 2px 7px;
    }
    .similar-email {
      cursor: pointer;
      &:hover {
        td {
          color: $colorPrimary;
        }
      }
    }
    &.notes-table {
      td {
        text-align: right;
        &:first-child {
          width: 50%;
          text-align: left;
          @media (max-width: 1100px) {
            max-width: 350px;
            word-break: break-all;
          }
        }
      }
    }
  }
  .save-sure {
    margin-right: 10px;
    &:hover {
      color: $colorPrimary;
    }
  }
  button.small {
    margin: 0 2px;
    padding: 0 5px;
    cursor: pointer;
    border: none;
    &:hover {
      background: rgba(128, 128, 128, 0.2);
    }
  }
  .controls {
    button {
      background: $colorPrimary;
      color: white;
    }
  }
  .fina {
    top: calc(50% - 145px);
    left: calc(50% - 33px);
    input {
      width: initial;
      border: 1px solid gray;
      padding: 5px;
      background: #f7f7f7;
      margin: 5px 0 10px;
      font-size: 16px;
      border-radius: 10px;
    }
    button {
      background: $colorPrimary;
      color: white;
      border-radius: 10px;
      border: 1px solid $colorPrimary;
      padding: 5px 20px;
      margin-top: 10px;
      font-size: 16px;
      &:hover {
        background: $colorSecondary;
        border-color: $colorPrimary;
        color: white;
        cursor: pointer;
      }
    }
  }
}

.main > .discounts,
.main > .agent {
  table {
    .hover {
      &:hover {
        background: rgba(128, 128, 128, 0.4);
        cursor: pointer;
      }
    }
  }
  button:not(.MuiButtonBase-root) {
    background: $colorPrimary;
    color: white;
    border-radius: 10px;
    border: 1px solid $colorPrimary;
    padding: 5px 20px;
    margin-top: 10px;
    font-size: 16px;
    &:hover {
      background: $colorSecondary;
      color: white;
      cursor: pointer;
    }
    &.green {
      background: $colorPrimarySuccess;
      border: 1px solid $colorPrimarySuccess;
      &:hover {
        background: $colorSecondarySuccess;
      }
    }
    &.red {
      background: $colorPrimaryError;
      border: 1px solid $colorPrimaryError;
      &:hover {
        background: $colorSecondaryError;
      }
    }
  }
}

.popup {
  background: #f2f2f2;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5), 0 10px 12px rgba(0, 0, 0, 0.15),
    0 10px 45px rgba(0, 0, 0, 0), inset 0 1px 0 white, inset 0 -2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px 30px 30px;

  overflow: hidden;
  font-size: 15px;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  button.yes {
    background: $colorPrimary;
    color: white;
    border-radius: 10px;
    border: 1px solid $colorPrimary;
    padding: 5px 10px;
    margin: 5px 15px 5px 10px;
    font-size: 16px;
    &:hover {
      background: $colorSecondary;
      border-color: $colorPrimary;
      color: white;
      cursor: pointer;
    }
  }
}

.discount_inputs {
  .MuiFormControl-root {
    margin-bottom: 10px;
    .MuiInputBase-root {
      width: 300px;
    }
  }
}

.MuiDialog-paperWidthSm {
  max-width: 800px !important;
}
// CSV view styles on rithmic-files page
.data-grid-container .data-grid .cell.tagReadyToRemoveBackground {
  background: $colorSecondaryError;
}
